.qr-code-2fa {
  border: 1px solid $td-blue;
  cursor: pointer;
}

.totp-secret {
  background-color: $pale-gray-two;
  border: 1px solid $graphite-light;
  border-radius: 4px;
  color: $warm-gray;
  height: 40px;
  line-height: 40px;

  div:first-of-type {
    width: 170px;
  }

  .copy {
    border-left: 1px solid $graphite-light;
    cursor: pointer;
  }
}
