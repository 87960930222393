@charset "utf-8";
/**
 * Projects specific SASS code
 */

.area-collapsible__toggle {
  margin-left: 30px;
}

.project-details {
  width: 100%;
  color: $warm-gray;
  font-size: 14px;
  font-weight: $font-weight-regular;
  line-height: 1.5;

  .status {
    text-transform: capitalize;
  }

  .card-body {
    padding: 0 !important;
    color: $greyish-brown;
  }
}

// Worksheet amend modal
.worksheet-amend {
  .input-sized {
    line-height: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    &--slim {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .form-group {
    margin-bottom: 0;
  }
}

.project-description-widget {
  h4, .project-field-label {
    color: $black;
    font-weight: $font-weight-semibold;
  }

  .project-field-label {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.opportunity-rate-guide i {
  --fa-secondary-color: #{$pending};
  --fa-secondary-opacity: 1;
}

.project-task-widget__details,
.project-task__details,
.project-widget__details {
  h4 {
    color: $black;
    font-size: 18px;
    font-weight: $font-weight-regular;
  }

  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    border-top-color: $pale-gray-two;
  }

  .task-meta,
  .project-meta {
    > div {
      border-bottom: 1px solid $pale-gray-two;
      padding: 8px 0;
    }

    .copy-task-reference {
      cursor: pointer;
    }
  }

  .project-meta .project-owner i {
    font-size: 14px;
  }

  .project-stats {
    > div {
      border-color: $pale-gray-two !important;

      span {
        display: block;
      }
    }

    &__label {
      font-size: 12px;
      text-transform: uppercase;
      color: $warm-gray;
      font-weight: $font-weight-semibold;
    }

    &__value {
      font-size: 20px;
      line-height: 1.2;
      font-weight: $font-weight-semibold;
    }
  }

  .budget-progress-bar__wrapper {
    position: relative;
    background: $graphite-light;
    width: 100%;
    height: 6px;
    overflow: hidden;
    border-radius: 3px;

    .td-progress-bar {
      top: 0;
      bottom: auto;
      right: auto;
      height: 6px;
      border-radius: 2px;
    }

    .indicator {
      height: 100%;
      width: 100%;
      opacity: .01;
    }
  }
  .project-worksheets-list {
    &__include-managers-container {
      font-size: 13px;
      line-height: 12px;
      &:hover {
        cursor: pointer;
      }
    }

    &__total-container {
      font-size: 13px;
      font-weight: 600;
      span:first-child {
        margin-right: 35px;
      }
    }
  }
  &-manager-list-popover {
    width: 180px;
    padding: 10px 15px;
  }
  &-popover {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      max-width: 200px !important;
      .popover-body {
        max-width: 180px !important;
        min-width: 180px !important;
      }
    }
  }
}

.date-duration-dropdown {
  .dropdown-toggle {
    position: relative;
    padding: 10px 16px 10px 5px;
  }
  .fal {
    font-size: 10px;
    position: relative;
  }
  .dropdown-label {
    font-size: 13px;
    color: $warm-gray;
  }
  .dropdown-menu {
    left: 13px !important;
  }

  &__form-field {
    color: #999;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #cfd7e1;
    background-color: #fff;
    line-height: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
}
// Project tasks
.task-checklist {
  &__item {
    .td-drop-button__toggle {
      visibility: hidden;

      &--active {
        visibility: visible;
      }
    }

    &:hover {
      .td-drop-button__toggle {
        visibility: visible;
      }
    }
    .input-group-prepend {
      .input-group-text {
        border: none;
        background: inherit;
      }
    }

    .editable-value {
      &--viewing {
        flex: 1;
        font-size: 14px;
        text-align: left;
        border: 1px solid transparent;
        background: inherit;
      }
    }
  }
  li + &__add-items {
    margin-top: 10px;
  }
}

.task-assignment,
.task-checklist {
  .btn-link {
    &.text-primary {
      &:hover, &:focus, &:active {
        color: $nice-blue !important;
      }
    }

    &.text-secondary {
      &:hover, &:focus, &:active {
        color: $gray !important;
      }
    }
  }
}

.task-rate-suggestion {
  .form-group {
    margin-bottom: 0;
  }
}

.task-assignment {
  .user-item {
    margin: -5px -10px;

    &__content {
      flex-wrap: wrap !important;
      justify-content: space-between;
    }

    &__rate {
      flex-basis: 30%;
      max-width: 30%;
      display: flex;
      justify-content: flex-end;
    }

    &__skills {
      padding: 10px 0 0 0 !important;
      order: 99;
      flex: 1 0 100%;
      max-width: 100% !important;
    }
  }
}


// Project Budget Progress Bar

.project-budget {
  .status-dot--info {
    margin-left: 0;
  }

  .project-budget__header {
    &-amount {
      font-size: 14px;
    }
  }
  .project-budget__line-container {
    width: 100%;
    position: relative;
    display: flex;

    .project-budget__line {
      position: relative;
      height: 6px;
      display: inline-flex;
      background-color: $warm-gray;
      border-radius : 4px;
      min-width: 2px;

      &:not(:first-child):not(:last-child) {
        border-radius: 0;
      }

      &:last-child:not(:first-child) {
        border-radius: 0 4px 4px 0;
      }

      &:first-child:not(:last-child) {
        border-radius: 4px 0 0 4px;
      }

      &--used,
      &--completed {
        background-color: $td-blue;
      }

      &--pending {
        background-color: $pending;
      }

      &--available {
        background-color: $medium-green;
      }

      &--refunded {
        background-color: $warm-gray-two;
      }
    }
  }
  .project-budget__info-container {
    display: flex;
  }
}


.project-widget__upcoming-payments {
  .card-body {
    padding: 0;
  }

  .upcoming-payments {
    padding: 10px 15px;

    &:not(:last-child) {
      border-bottom: 1px solid $graphite-light;
    }

    &__totals-container {
      background-color: rgba($pending, 0.1);
      font-weight: 600;
    }
  }
  .upcoming-amount,
  .upcoming-value,
  .upcoming-label {
    white-space: nowrap;
  }
}


// Project list
.project-list {
  margin-left: 0;
  padding-left: 0;
  list-style: none;

  hr {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  &__item {
    &--title {
      margin: 0 0 15px 0;
      display: inline-block;
      font-weight: $font-weight-regular;

      a {
        line-height: 22px;
        font-size: 18px;
        color: $black;
      }
    }

    &--label {
      margin-right: 5px;


      & + span {
        color: $black;
      }
    }

    &--status {
      text-transform: capitalize;
      flex: 1;
    }

    &--progress {
      margin-bottom: 0;
      line-height: 1;
      display: flex;

      &__percentage {
        justify-content: flex-end;
      }
    }
  }

  .budget-progress-bar {
    &__wrapper {
      margin-top: 5px;
      position: relative;
      height: 4px;
      border-radius: 2px;
      width: 100%;
      overflow: hidden;
      background: $graphite-light;

      .td-progress-bar {
        width: 10%;
        height: 100%;
        top: 0;
        border-radius: 2px;

        .indicator {
          display: none;
        }
      }
    }
  }
}

.pie-chart-container {
  .highcharts-data-label,
  .highcharts-data-label-connector {
    display: none;
  };
  .status-dot {
    margin-left: 0;
  }
  .pie-chart-info-table {
    font-size: 12px;
  }
  &__no-data {
    .no-data-msg {
      font-size: 13px;
      position: absolute;
    }
  }
}
.chart-stacked-container {
  .highcharts-scrollable-mask {
    // removes vertical fadeout when scrolling horizontally on stacked chart
    display: none;
  }
}

// this is an exception in UI modifications in specific screen sizes where we have a rendering wrap issue with pie chart
@media (max-width: 1400px) and (min-width: 768px) {
  .pie-chart-container {
    display: flex;
    flex-direction: column;
    .col-12 {
      max-width: 100% !important;
    }
  }
  .pie-chart-info-table {
    max-width: 100% !important;
  }
}

@media (min-width: 1400px) {
  .pie-chart-container .no-data-msg span:first-child {
    display: none;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .project-list {
    &__item {
      &--title {
        margin-bottom: 5px;
      }

      &__deadline {
        float: none !important;
        display: block;
        clear: both;
      }
    }
  }

  .project-budget__info-container {
    flex-direction: column;
    div {
      margin-bottom: 5px;
    }
    .status-dot {
      margin-left: 0;
    }
  }

}

#remove-payments-reviewer {
  background-color: $pale-gray-two;

  span:first-of-type {
    color: $warm-gray-two;
  }
}

.invoice-cap-usage-bar {
  &__text div {
    width: auto;
    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 50%;
    }
  }

  .usage-amount {
    cursor: pointer;
  }

  .bar {
    background-color: $pale-gray;
    border-radius: 4px;
    height: 8px;
    overflow: hidden;

    .used {
      background-color: $medium-green;
    }

    .additional {
      background-color: $warning;

      &.more-than-100 {
        background-color: $tomato-red;
      }
    }
  }
}

.add-members-skills .skill-list.skill-list--inline {
  overflow: visible;

  .truncate {
    overflow: visible;

    .tag {
      margin-bottom: 0;
    }
  }
}

.manage-projects-modal-list .manage-projects-modal-item {
  border: 1px solid $graphite-light;

  i {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$tomato-red};
    --fa-secondary-opacity: 1;
  }
}

.project-form {
  .project-icon {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$tomato-red};
    --fa-secondary-opacity: 1;
  }

  .opportunity-icon {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$td-blue};
    --fa-secondary-opacity: 1;
  }
}

@keyframes icon-spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page--remove-project-member .btn {
  .btn-icon {
    animation: icon-spin-animation 1.5s infinite linear;
  }

  &.loading {
    color: $white;
  }
}
