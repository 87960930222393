$panel-width: 390px;

#get-started-page {
  .step-row {
    border-radius: $borderRadius;
    border: 1px solid $graphite-light;
    padding: 22px;
    background-color: $white;
    color: $black;
    transition: all .15s;

    &:hover {
      text-decoration: none;
      color: $black;
      text-decoration: none;
      border-color: $td-blue;

      .step-row__state-icon {
        color: $td-blue;
      }
    }

    &--completed {
      &:hover {
        border-color: $graphite-light;

        .step-row__state-icon {
          color: $td-blue;
        }
      }
    }

    &--uc-pending,
    &--uc-submitted {
      .step-row {
        &__icon-container {
          opacity: .5;
        }
      }
      &:hover {
        border-color: $graphite-light;
      }
    }

    &--uc-rejected {
      .step-row {
        &__icon-container {
          &--state-icon {
            display: none;
          }
        }
      }
    }

    &:active {
      background-color: #f7fafa;
    }

    + .step-row {
      margin-top: 8px;
    }

    &__icon-container {
      height: 52px;
      width: 52px;
      margin-right: 22px;
    }

    &__icon {
      font-size: 48px;

      &:before,
      &:after {
        opacity: 1;
      }

      &.fa-circle-user {
        &:before {
          color: $black;
        }
        &:after {
          color: $pending;
        }
      }

      &.fa-percent{
        &:before {
          color: $tomato-red;
        }
        &:after {
          color: $black;
        }
      }

      &.fa-wallet{
        &:before {
          color: $td-blue;
        }
        &:after {
          color: $black;
        }
      }

      &.invoice-tax {
        background-image: url('../../../../public/img/invoice-tax.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        height: 100%;
        width: 100%;
      }

      &.fa-clipboard-list-check{
        &:before {
          color: $black;
        }
        &:after {
          color: $tomato-red;
        }
      }

      &.fa-file-certificate{
        &:before {
          color: $black;
        }
        &:after {
          color: $tomato-red;
        }
      }
    
      &.brand_blue {
        &::after {
          color: $td-blue;
        }
      }

      &.fa-shield-check {
        &:before {
          color: $black;
        }
        &:after {
          color: $tomato-red;
        }
      }
    }

    &__state-icon {
      color: $warm-gray;
      font-size: 20px;
      margin-left: 20px;
      transition: all .15s;
    }

    &--blocked {
      .step-row {
        &__title,
        &__description {
          color: $warm-gray;
        }

        &__state-icon {
          color: $pending;
          font-size: 32px;
        }
      }

      &:hover {
        border-color: $graphite-light;

        .step-row {
          &__state-icon {
            color: $pending;
          }
        }
      }
    }

    &--completed {
      .step-row {
        &__title,
        &__description {
          color: $warm-gray;
        }

        &__state-icon {
          font-size: 32px;
          color: $medium-green;
        }

        &__icon-container {
          opacity: 0.5;

          &--state-icon {
            opacity: 1;
          }
        }
      }
    }

    &--uc-submitted,
    &--uc-pending {
      .step-row {
        &__title,
        &__description {
          color: $warm-gray;
        }

        &__state-icon {
          color: $pending;
          opacity: 1;
        }
      }

      &:hover {
        .step-row {
          &__state-icon {
            color: $pending;
          }
        }
      }
    }

    &--AMLStep .fa-user-check {
      --fa-secondary-color: #{$pending};
    }

    &--UserCardPaymentMethodStep .fa-hand-holding-dollar {
      --fa-secondary-color: #{$pending};
    }

    &--SkillsStep .fa-pen-ruler {
      --fa-secondary-color: #{$tomato-red};
    }

    &--LanguagesStep .fa-earth-americas {
      --fa-secondary-color: #{$td-blue};
    }
  }
}

.get-started-progress {
  font-weight: normal;

  .bar-wrapper {
    background-color: $athens-gray;
    border-radius: 2px;
    height: 4px;
    width: 100%;

    .bar {
      background-color: $pending;
      border-radius: 2px;
      float: left;
      height: 100%;

      &.complete {
        background-color: $medium-green;
      }
    }
  }
}


#get-started-complete i {
  font-size: 7rem;
}
