.interview-review {
  .interview-answer {
    background-color: $pale-gray-two;
    border: 1px solid $graphite-light;
    font-size: 14px;
    color: $greyish-brown;
    padding: 15px;
    margin-bottom: 10px;

    p {
      margin-bottom: 0;

      + p {
        margin-top: 15px;
      }
    }
  }
  .interview-header {
      margin-top: 10px;
      margin-bottom: 20px;
  }

  .text-warning {
    background-color: $warning-bg;
  }
}

.interview-amend {
  .has-error{
    .form-control {
      border-color: $silver;

      &:focus {
        box-shadow: none;
      }
    }

    .help-block {
      color: #ea6945;
      border-radius: $borderRadius;
      padding: 15px;
      font-weight: $font-weight-regular;
      font-size: 14px;
      background-color: rgb(253, 241, 237);
    }
  }
}

.interview-builder {
  &--preview {
    a {
      pointer-events: none;
    }
  }

  padding: 0;

  .error-summary {
    padding-left: 20px;
  }

  &__tabs {
    padding: 0;
  }

  &__controls {
    display: flex;
    box-shadow: 0 -1px 0 0 #f0f3f6;
    margin: 0 -15px -15px;
    overflow-x: auto;

    > button {
      margin: 0 !important;
      flex: 1;
      flex-basis: 85px;
      flex-shrink: 0;
      text-align: center;
      font-size: 13px;
      color: $warm-gray;
      text-transform: none;
      border-radius: 0;
      box-shadow: inset -1px 0 0 0 #f0f3f6;
      border: none;
      padding: 10px;
      height: auto;

      &:last-child {
        box-shadow: none;
      }

      span {
        display: block;
        clear: both;
        color: $graphite-light;
        margin: 0 auto 10px !important;
        font-size: 25px !important;
        line-height: 25px!important;
      }

      &:hover,
      &:active {
        background-color: #fcfcfd;
        color: $black;

        span {
          color: $td-blue;
        }
      }
    }
  }

  &__empty-list-message,
  &__intro-message {
    padding: 14px;
    color: #555;
    font-weight: 300;
    text-align: center;
  }

  &__intro-message {
    padding: 50px;
  }

  &-dropdown-control {
    &__line {
      .add-link {
        display: none;
      }
      display: flex;
      clear: both;
      margin-top: 10px;

      input {
        margin-right: 10px;
      }

      .input--markdown-input {
        .add-link {
          right: 18px;
        }
      }

      .fa-times {
        display: inline-block;
        margin: 10px 10px 0;
        color: $warm-gray;
        font-size: 14px;
        cursor: pointer;

        &:hover,
        &:active {
          color: $black;
        }
      }
    }
  }

  &__question {
    .radio-inline + .radio-inline {
      margin-left: 20px;
    }

    label {
      padding-left: 0;
    }

    .form-group {
      clear: both;

      > label {
        float: left;
        margin-right: 15px;
      }
    }

    .form-control + .form-control {
      margin-top: 10px;
    }

    .title-container {
      h4 {
        width: calc(100% - 5rem);
      }

      .change-order-up, .change-order-down {
        color: $warm-gray;
        cursor: pointer;
        line-height: 1;
      }
    }
  }

  &__question-title {
    margin: 0 0 15px;
    padding-left: 17px;
    color: $greyish-brown;
    font-weight: $font-weight-semibold;
    font-size: 14px;

    span {
      font-weight: $font-weight-semibold;
      color: $warm-gray;
    }
  }

  &__question-remove {
    color: $graphite-light;
    font-weight: $font-weight-semibold;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: $graphite-dark;
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.2);
      cursor: default;
    }
  }

  &__questions-list {
    padding: 20px 0;
  }

  &__question {
    position: relative;
    background-color: $pale-gray-two;
    padding: 15px 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__template-name {
    padding: 20px 0 0;

    .form-group{
      margin-bottom: 0;
    }
  }
}

.yes-no-field {
  .form-check-input {
    margin-left: -15px;
  }

  .custom-radio {
    margin-left: 0;
  }

  &.disabled {
    opacity: 0.5;

    + .warning {
        opacity: 0.5;
        font-weight: bold;
        margin-bottom: 1em;
        display: block;
    }
  }
}

.signature-field-container {
  border: 1px solid $silver;
  padding: 15px;
  border-radius: $borderRadius;

  .filename {
    display: inline-block;
    margin-bottom: 3px;
  }

  .help-block {
    margin: 0;
  }
}

.signature-field-container--error {
  background-color: $error-block-background;
  border-color: $tomato-red;
}

.submission-view-modal {
  label {
    font-size: 16px;
    font-weight: $font-weight-semibold;
  }
}

.interview-list-item {
  border: 1px solid $graphite-light;
  background-color: $list-item-background-color;
  padding: 15px;
  border-radius: 4px;

  + .interview-list-item {
    margin-top: 10px;
  }

  &__name {
    font-size: 14px;
    color: $black;
    font-weight: $font-weight-regular;
    margin: 0;
  }

  &__pending_invitation_meta {
    font-size: 13px;
    color: $black;
    font-weight: $font-weight-regular;
    margin: 0;
  }

  &__meta {
    font-size: 13px;
    color: $warm-gray;
  }

  .button-container {
    padding-top: 10px;

    .btn{
      padding: 0;

      .fal, .fas, .fa {
        margin-right: 0;
      }
    }
  }
  .area-collapsible__toggle {
    margin-right: 5px;
  }
}

.page.page--interviews {
  .submission-question-wrap {
    margin-bottom: 2em;
  }
  .submission-question-wrap.disabled {
    opacity: 0.5;

    > .warning {
      font-weight: bold;
    }
  }
}

.interview-card-item  i {
  --fa-secondary-color: #{$tomato-red};
  --fa-secondary-opacity: 1.0;
}
