.solo-container {
  align-items: center;
  background-image:
    url('~core/assets/img/login-signup/line.png'),
    url('~core/assets/img/login-signup/angle-top-left.png'),
    url('~core/assets/img/login-signup/angle-bottom-right.png'),
    url('~core/assets/img/login-signup/curve-up.png'),
    url('~core/assets/img/login-signup/line.png'),
    url('~core/assets/img/login-signup/curve-left.png'),
    url('~core/assets/img/login-signup/angle-top-left.png'),
    url('~core/assets/img/login-signup/curve-down.png'),
    $splash-bg;
  background-position:
    28% 68%,
    79% 56%,
    65% 83%,
    42% 90%,
    81% 26%,
    21% 33%,
    33% 13%,
    54% 4%;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: auto;
  padding: 50px 0;
  width: 100%;
  @media (max-width: map-get($grid-breakpoints, sm)) {
    background-image: $splash-bg;
  }

  h1 {
    color: $warm-gray;
    font-weight: $font-weight-semibold;
    font-size: 16px;
    padding-left: 0;
  }

  &__box {
    background-color: #fff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 50px;
    width: 500px;
    border-radius: 4px;
    position: relative;

    &--flipped {
      width: 700px;
    }

    &--compact {
      padding: 30px;
    }
  }

  &__logo-container {
    display: inline-block;
    text-align: center;

    img {
      display: inline-block;
      margin-bottom: 20px;
    }
  }

  .main-content {
    padding-top: 40x;
  }
}

a.solo-container__logo-container {
  max-width: 80%;
  width: 180px;
}

@media (max-width: map-get($grid-breakpoints, lg)) {
  .solo-container {
    &__box {
      width: auto;
      max-width: 100%;
      padding: 80px 25px 50px;
    }
  }
}
