.login-form {
  .recovery-code-back {
    color: $warm-gray;
    cursor: pointer;
    position: absolute;
    top: 60px;
    left: 50px;
  }
}

[name=totp] {
  font-size: 48px;
  max-width: 240px;
  padding: 40px 0 40px 0;
  text-align: center;
}
