.rich-text-area [class^=RichTextEditor__root] {
  border: 0;
  display: flex;
  font-family: $text-font-stack;
  flex-direction: column;

  [class^=EditorToolbar__root] {
    background-color: $pale-gray;
    border-bottom: 1px solid $graphite-light;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem;

    [class^=ButtonGroup] {
      margin-bottom: 0;

      button {
        background: none;
        background-color: $pale-gray;
        border: 0;

        &[class*=isActive] {
          background-color: $graphite-light;
        }
      }
    }
  }

  [class^=RichTextEditor__editor] {
    background-color: $pale-gray;
    flex: 1;
    overflow: auto;
    padding: 3rem;

    .DraftEditor-root {
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      display: flex;
      height: auto;
      min-height: 100%;
      padding: 3rem;
    }

    .DraftEditor-editorContainer,
    .DraftEditor-editorContainer div {
      height: auto;
      min-height: 100%;
      width: 100%;
    }
  }
}

.TDRichTextArea {
  &__wrapper {
    height: calc(100vh - 440px) !important;
    overflow: hidden;
    background-color: #edf2f2;
    border-radius: 3px;
    padding: 2rem;
  }

  &__toolbar {
    border-bottom: 1px solid $warm-gray-two;
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  &__button {
    background: none;
    border: 0;
    cursor: pointer;
    margin-right: 1rem;
    padding: 0;

    &:hover {
      background-color: #edf2f2;
    }
  }

  &__icon {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
  }

  &__editor {
    height: 100%;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0,0,0,.15);
    border-radius: 4px;
    padding: 3rem;
  }

  &__bold {
    font-weight: bold;
  }

  &__italic {
    font-style: italic;
  }

  &__underline {
    text-decoration: underline;
  }

  &__strikethrough {
    text-decoration: line-through;
  }

  &__code {
    background-color: $pale-gray-seven;
    color: $greyish-brown !important;
    font-size: 14px !important;
    word-wrap: normal !important;
  }

  &__ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 15px !important;
  }

  &__ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 15px !important;
  }

  &__quote {
    font-size: 14px;
    color: $warm-gray;
    position: relative;
    overflow: hidden;
    padding: 10px 10px 10px 20px;
  }
}
