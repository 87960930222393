@keyframes bar1-width {
  0% {
    width: 70%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 70%;
  }
}

@keyframes bar2-width {
  0% {
    width: 100%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}
  
@keyframes bar3-width {
  0% {
    width: 50%;
  }

  50% {
    width: 80%;
  }

  100% {
    width: 50%;
  }
}

$financial-charts-worksheet: $dark-purple;
$financial-charts-expense: $tomato-red;
$financial-charts-pro-forma: $td-blue;

.financial-charts-wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    grid-template-columns: 1fr;
  }

  .card + .card {
    margin-top: 0;
  }

  .financial-chart-wrapper {
    color: $black;
    height: 410px;

    .card-header {
      border-bottom-color: $pale-gray;
      color: $black;
    }

    .financial-chart-skeleton {
      background-color: $pale-gray;
      height: 100%;
      width: 100%;

      .bars-wrapper {
        width: 56px;

        div {
          animation-duration: 1s;
          animation-iteration-count: infinite;
          background-color: $graphite-dark;
          border-radius: 6px;
          height: 16px;
        }

        div:first-of-type {
          animation-name: bar1-width;
        }

        div:nth-of-type(2) {
          animation-name: bar2-width;
          opacity: 0.4;
        }

        div:last-of-type {
          animation-name: bar3-width;
        }
      }
    }

    .card-body {
      .total {
        font-size: 32px;
      }

      .dot {
        border-radius: 4px;
        display: inline-block;
        height: 8px;
        width: 8px;

        &.approved-worksheets, &.pending-worksheets {
          background-color: $financial-charts-worksheet;
        }
  
        &.approved-expenses, &.pending-expenses {
          background-color: $financial-charts-expense;
        }
  
        &.approved-pro-forma, &.pending-pro-forma {
          background-color: $financial-charts-pro-forma;
        }
  
        &.pending-worksheets, &.pending-expenses,  &.pending-pro-forma {
          opacity: 0.4;
        }
      }
    }

    .card-footer {
      background: $white;
      border-top: 1px solid $pale-gray;
      color: $black;
    }
  }
}

.financial-chart-counts-list {
  .entry {
    height: 24px;

    .progress-wrapper {
      position: relative;

      .progress-bar, .count {
        position: absolute;
        top: 0;
        left: 0;
      }

      .progress-bar {
        background-color: $graphite-light;
        border-radius: 6px;
      }

      .count {
        line-height: 24px;
      }
    }
  }
}

.financial-charts-date-picker-wrapper {
  .icon {
    background-color: $pale-gray;
    border: 1px solid $gray-400;
    border-left: 0;
  }
}

.upcoming-payments-chart {
  .keys {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .key {
      .dot {
        &.approved-worksheets, &.pending-worksheets {
          background-color: $financial-charts-worksheet;
        }
  
        &.approved-expenses, &.pending-expenses {
          background-color: $financial-charts-expense;
        }
  
        &.approved-pro-forma, &.pending-pro-forma {
          background-color: $financial-charts-pro-forma;
        }
  
        &.pending-worksheets, &.pending-expenses,  &.pending-pro-forma {
          opacity: 0.4;
        }
      }

      .label-wrapper {
        width: calc(100% - 16px);
      }
    }
  }

  .upcoming-payments-pie-chart {
    .approved-worksheets {
      fill: $financial-charts-worksheet;
    }

    .pending-worksheets {
      fill: transparentize($financial-charts-worksheet, 0.4);
    }

    .approved-expenses {
      fill: $financial-charts-expense;
    }

    .pending-expenses {
      fill: transparentize($financial-charts-expense, 0.4);
    }

    .approved-pro-forma {
      fill: $financial-charts-pro-forma;
    }

    .pending-pro-forma {
      fill: transparentize($financial-charts-pro-forma, 0.4);
    }
  }
}

.spend-by-type-chart {
  .key {
    background-color: $pale-gray;
    border-radius: 4px;

    .dot {
      &.worksheets {
        background-color: $financial-charts-worksheet;
      }

      &.expenses {
        background-color: $financial-charts-expense;
      }

      &.proforma-invoices {
        background-color: $financial-charts-pro-forma;
      }
    }
  }

  .spend-by-type-bar-chart {
    overflow: visible !important;

    .worksheets rect {
      fill: $financial-charts-worksheet;
    }

    .expenses rect {
      fill: $financial-charts-expense;
    }

    .proforma-invoices rect {
      fill: $financial-charts-pro-forma;
    }
  }
}

.spend-by-providers-list {
  .provider {
    .provider-wrapper {
      width: calc(100% - 90px);

      .progress-wrapper {
        width: calc(100% - 40px);

        .progress {
          background-color: $dark-purple;
          height: 4px;
        }
      }
    }
  }
}

.total-spend-chart {
  .total-spend-change {
    background-color: $medium-green-pastel;
    border-radius: 4px;
    color: $medium-green;

    &.descrease {
      background-color: $tomato-red-pastel;
      color: $tomato-red;
    }
  }

  .highcharts-line-series path {
    stroke: $dark-purple;
  }
}
