.user-contact-details-card {
  .row + .row {
    margin-top: 10px;
  }
}

.rate-adj-form {
  textarea.form-control {
    height: 90px;
  }
  &__respond,
  &__request {
    background-color: $pale-gray-two;
  }
}


$user-group-border-gray: $gray-100;
.user-group {
  &-list {
    .card:not(:first-child) {
      margin-top: 10px;
    }
  }
  &-card {
    .description {
      border-right: 1px solid $user-group-border-gray;
    }
    .creator,
    .created-at {
      border-bottom: 1px solid $user-group-border-gray;
    }
  }
  &-members {
    .empty-list-message {
      margin-top: 50px;
    }
  }
}

.provider-tax-information {
  .provider-tax-information-meta {
    @include details-rows;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .company-logo {
      max-width: 100%;
      max-height: 150px;
      object-fit: contain;
    }
  }
}

.provider-payment-details {
  .provider-payment-details-meta {
    @include details-rows;
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .user-group {
    &--card {
      .card-body {
        border-bottom: 1px solid $user-group-border-gray;
        border-radius: 0;
      }
      border-bottom: 1px solid $user-group-border-gray;
      .description,
      .created-at,
      .creator {
        border: none;
      }
    }
  }
}


.page {
  &--people {
    th:last-child {
      border-left: none;
    }

    .invite-people-adding {
      .adding-info-overlay-icon {
        color: $black;
      }
    }
  }
}

$discover-talent-banner-logos-width: 410px;

.discover-talent-banner {
  background-color: $graphite-light;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  color: $black;

  .text {
    padding: 16px;
  }

  .logos {
    height: 100px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::before {
      background-color: #fff;
      border-radius: 100%;
      content: "";
      display: inline-block;
      left: -50%;
      padding-bottom: 200%;
      position: absolute;
      top: 0;
      width: 200%;
      z-index: 1;
    }

    .content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      img {
        height: 16px
      }

      span {
        font-size: 16px;
        pointer-events: none;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    height: 220px;

    .text {
      padding: 0 48px;
      width: calc(100% - #{$discover-talent-banner-logos-width});

      p {
        font-size: 16px;
      }
    }

    .logos {
      height: 100%;
      width: $discover-talent-banner-logos-width;

      &::before {
        left: 0;
        padding-bottom: 150%;
        top: -60%;
        width: 150%;
      }

      .content {
        width: 200px;

        img {
          height: 32px
        }
      }
    }
  }
}

.freelancer-item {
  height: 400px;
  cursor: pointer;
  border: 1px solid $graphite-light;
  box-shadow: none;

  &__extended {
    height: 505px;
  }

  &__reply {
    border-top: 1px solid $pale-gray;
    &-icon {
      color: $td-blue!important;
    }

    &-message {
      font-weight: $font-weight-semibold;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &:hover,
  &:active {
    background-color: $pale-gray-two;
  }

  &__container {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      padding-right: 0 !important;
    }
  }

  &__basic-info {
    width: 100%;
  }

  &__fav {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 2;

    &-icon {
      color: $tomato-red !important;
    }
  }

  &__title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 2px;
      font-size: 16px;
      line-height: 1.5;
      color: $black;
    }
    .fflag {
      vertical-align: text-bottom;
    }
  }

  &__footer {
    background-color: initial !important;
    word-break: break-word;
  }

  &__extra {
    font-size: 14px;
    width: 100%;
    color: $greyish-brown;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 40px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    span {
      margin-right: 0;
    }
  }

  &__muted {
    color: $warm-gray;
  }

  &__small {
    font-size: 12px;
    line-height: 1.2;
    vertical-align: bottom;
  }

  &__action {
    position: absolute;
    top: 6px;
    right: 12px;
  }

  &__details {
    min-height: 26px;
  }

  .onboarding-talent__status {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.search-banner-widget-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 320px;
  padding: 30px 48px;
  border-radius: 4px;
  margin-bottom: 36px;
  background: url('~core/assets/img/people/search-bg.png'), $graphite-light;
  box-shadow: $shadow-sm;
  border-radius: 4px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding: 77px 48px;
  }

  &__title {
    margin-bottom: 7px;
    margin-top: 0px;
  }

  &__link {
    background: $pale-gray !important;
    color: $warm-gray !important;
  }

  &__input {
    input {
      height: 48px;
      padding: 14px 16px;
    }
    .search-button {
      top: 8px;
      right: 5px;
    }
    .clear-kw {
      top: 5px;
      right: 41px;
    }
  }

}

.category-section {
  height: 188px;
  cursor: pointer;

  &__container {
    display: flex;
    align-items: center;
    padding: 30px !important;
    text-align: center;
    flex-direction: column;

    &:hover,
    &:active {
      background-color: $pale-gray-two;
    }
  }

  &__item {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 0 0 20%;
      max-width: 20%
    }
  }

  i {
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;
    font-size: 6.3rem;
  }

  .fa-paintbrush-pencil {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$tomato-red};
  }

  .fa-typewriter {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending};
  }

  .fa-laptop-code {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$td-blue};
  }

  .fa-megaphone {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$td-blue};
  }

  .fa-briefcase {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$td-blue};
  }

  .fa-camera-retro {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending};
  }

  .fa-music {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending};
  }

  .fa-bullseye-arrow {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$tomato-red};
  }

  .fa-comments {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$td-blue};
  }

  .fa-scale-balanced {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending};
  }

}

.how-it-works {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  align-items: center;
  color: $black;

  &__title, h1 {
    font-weight: 400;
    font-size: 26px;
    line-height: 1.5;
    margin-bottom: 0;
  }

  &__sub, h3 {
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    font-weight: 400;
    background: transparent;
    margin-top: 0;
    margin-bottom: 4rem;
    padding-top: 0;
  }

  &__semibold, h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  }

  &__arrow {
    font-size: 24px;
    color: $graphite-light;
    margin: 4rem 0;
  }

  &__close {
    color: $warm-gray;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    font-size: 18px;
  }

  &__icon {
    font-size: 24px;
    padding: 20px;
    color: white;
    border-radius: 50%;
  }

  .fa-search {
    background: $td-blue;
  }

  .fa-comment {
    background: $tomato-red;
  }

  .fa-envelope-open-text {
    background: $pending;
  }

  .fa-check {
    background: $medium-green;
  }

}

.freelancer-details-card {
  &__seperator {
    border-top: 1px solid $pale-gray;
    padding: 1.5rem 1.5rem;
    margin: 0;
  }

  &__portfolio-image {
    border: 9px solid $pale-gray;
    object-fit: cover;
    height: 150px;
    width: 100%;
    cursor: pointer;
  }

  .dropdown-toggle {
    display: inline-block;
  }
}

.talent {
  &__portfolio {
    &-container {
      height: 320px;
      overflow-y: auto;
      padding-left: 16px;
    }
  }

  &__image {
    padding-right: 16px !important;
  }

  .image-gallery-image {
    height: 400px;
  }

  .image-gallery-icon {
    color: $td-blue;
    filter: none;
    border: none;
    &:hover, &:active, &:focus {
      color: $td-blue;
      filter: none;
    }
  }

  .image-gallery-slide-wrapper {
    padding-bottom: 30px;
  }

  .image-gallery-download-action {
    position: absolute;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      right: 5.25rem;
    }
    right: 0rem;
    bottom: 0.75rem;
  }
}

.freelancer-widget {

  &__title {
    line-height: 19px !important;
  }

  &__profile {
    &-image {
      display: flex;
      justify-content: center;
      @media (max-width: map-get($grid-breakpoints, md)) {
        margin-bottom: 1rem;
      }
    }

    &-content {
      padding-left: 0;
      overflow: hidden;
      @media (max-width: map-get($grid-breakpoints, md)) {
        padding-left: 15px;
      }
    }
  }

  &__job {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: $greyish-brown;
  }

  &__skills {
    margin-top: 0.5rem;
    line-height: 25px;
  }

  &__refresh {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      display: none !important;
    }
  }
}

.freelancer-chat {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 480px;
  @media (max-width: map-get($grid-breakpoints, sm)) {
    width: 100%;
  }

  .area-collapsible__toggle {
    margin-left: 0;
  }

  &__container {
    width: 100%;
    background-color: $graphite-dark;
    border-top-left-radius: 4px;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.15);

    h3 {
      .fa-chevron-up, .fa-chevron-down {
        color: $white;
      }
    }
  }

  &__header {
    max-width: 100% !important;
    span {
      color: $white;
    }

    label {
      color: $white;
      font-weight: $font-weight-semibold;
    }

    .badge {
      background-color: $td-blue;
      color: $white;
      font-weight: $font-weight-semibold;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 11px;
      line-height: 20px;
      margin-top: -1px;
      border: none;
    }
  }

  &__body {
    background-color: $white;

    .chat__box-body {
      height: 450px;
      overflow-y: auto;
    }

  }
}

.invite-aor-checkable-list {
  .checkable-list__item {
    position: relative;

    .logo {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 6rem;

      &.centered {
        height: 4rem;
        width: auto;
      }
    }

    .cost {
      color: $medium-green;
    }

    .popular {
      color: $tomato-red;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    li + li {
      margin-top: 0;
    }
  }

  &.centered {
    .logo {
      width: 5rem;
    }

    .popular {
      left: 0;
      right: auto;
    }
  }
}

.org-messaging {
  .fa-comments {
    font-size: 64px;
    --fa-primary-color: #{$graphite-dark};
    --fa-secondary-color: #{$graphite-dark};
    --fa-secondary-opacity: 0.4;
  }

  .badge {
    background-color: $td-blue;
    color: $white;
    font-weight: $font-weight-semibold;
    min-width: 15px;
    height: 20px;
    border-radius: 50%;
    font-size: 11px;
    line-height: 20px;
    margin-top: -1px;
    border: none;
  }

}
.select-people-menu {
  .select-people-list {
    overflow-y: scroll;
    max-height: 500px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .selected-item {
    border-radius: $borderRadius;
    border: 1px solid $graphite-light;
    background-color: $white;
    span {
      vertical-align: text-bottom;
    }
  }

  .item {
    border-radius: $borderRadius;
    &:hover {
      background: $pale-gray;
    }
  }
}

.org-messaging {
  height: 100%;
}

.talent-market-indicator {
  align-items: center;
  background: linear-gradient(90deg, $black 0%, $dark-purple 100%);
  border-radius: 100%;
  color: $white;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  left: 35px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 24px;
  z-index: 3;
}

.chat {
  height: 100%;

  background-color: $pale-gray;

  &__participants-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 80px 0 1;
    padding: 16px 22px;
    background-color: $pale-gray;
    transition: flex-basis 1s;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 75px;

    .text-truncate {
      display: inline-block;
      width: 135px;
    }

    .hide-on-collapsed {
      line-height: 1;
      opacity: 0;
      // display: none;
      font-size: 14px;
      transition: opacity .3s ease;
    }

    li {
      & + li {
        margin-top: 15px;
      }
    }

    &--expanded {
      flex-basis: 210px;
      // overflow-x: hidden;

      .hide-on-collapsed {
        // display: block;
        opacity: 1;
      }
    }
  }

  &__participants-list-toggle {
    text-align: center;
    padding-left: 5px;

    > div {
      width: auto;
      height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 14px;
      padding: 0 5px;
      color: $black;

      &:hover,
      &:active {
        span {
          color: $primary;
        }
      }
    }
  }

  &__participants-list-item {
    position: relative;

    .job-title {
      color: $warm-gray;
    }


    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      span,
      strong {
        color: $black;
      }
    }
    .add__icon {
      color: $black;
      background-color: $white;
      font-size: 20px;
      cursor: pointer;
    }
  }



  &__footer {
    background-color: $white !important;
    border-top: 1px solid $pale-gray !important;
  }

  &__body {
    height: calc(70vh - 66px - 78px);
    overflow-y: auto;
  }

  &__icon {
    color: $graphite-dark;
    font-size: 16px;
    width: 36px;
    height: 36px;
    flex-basis: 36px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: $pale-gray;
    border-radius: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &__header {
    font-size: 14px;
    min-height: 66px;
    background-color: $white;
    h4 {
      font-weight: bold;
    }
    i {
      color: $greyish-brown;
      margin-right: 1em;
    }
  }

  &__navbar {
    &-col {
      display: flex !important;
    }
    .navbar__menuItem {
      border-radius: 0;
      border-bottom: 1px solid $graphite-light;
    }

    .navbar__sectionItem {
      border-radius: 0;
      transition: all 0.2s ease;

      &-active {
        background: $graphite-dark !important;
        color: #fff !important;

        .chat__icon {
          color: $graphite-dark;
        }
      }


      &:hover {
        background: $pale-gray-three;
      }
    }
  }

  .pagination-container__page-info {
    display: none;
  }

  &__text {
    width: 80%;
    color: $black;
  }
}

.invoice-cap-widget-form .equals {
  margin-top: 32px;
}

.invoice-cap-widget-overview {
  background-color: $pale-gray;
  border-radius: 4px;

  .auto-approval-indicator {
    color: $medium-green;

    &.disabled {
      color: $graphite-light;
    }
  }
}

.deactivate-manager-form .selection-field {
  width: 150px;
  margin-bottom: 0;
}

.cards-grid-view {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 576px) {
    grid-template-columns: 100%;
  }
}

.team-grid-list-item {
  .team-grid-list-item__container {
    border: 1px solid $graphite-light;
    border-radius: 4px;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .skill-list {
    .tag {
      margin: 0 8px 8px 0;

      > span {
        font-size: 12px;
        line-height: 16px;
        padding: 4px 8px;
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  .status--default {
    background-color: $pale-gray;
    color: $black;
    font-size: 14px;
  }

  .get-started-progress {
    font-size: 12px;
  }

  .fflag {
    width: 25px;
    height: 15px;
  }

  &__indicators {
    color: $graphite-dark;
    font-size: 10px;
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: default;
  }

  &__actions-drop-down {
    &--fixed{
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .dropdown-menu[x-placement='top-end'] {
      margin-bottom: 15px;

      &:before {
        border-bottom: none;
        border-left: 8px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid rgba(0, 0, 0, .09);
        top: unset;
        bottom: -10px;
        right: 8px;
      }

      &:after {
        border-bottom: none;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #fff;
        top: unset;
        bottom: -8px;
        right: 9px;
      }
    }
  }
}

.invite-people-role-option i {
  --fa-secondary-color: #{$tomato-red};
  --fa-secondary-opacity: 1;
}

.user-group-create-select-type,
.user-group-card--preview,
.manage-user-groups-modal-list,
.user-group-card,
.opportunity-invite-groups-modal .group-option,
.opportunity-invite-groups-modal .selected-group {
  i {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending};
    --fa-secondary-opacity: 1;
  }

  [class*="fa-user"] {
    --fa-secondary-color: #{$tomato-red};
  }
}

.user-group-create-select-type {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;

  li + li {
    margin-top: 0;
  }
}

.user-group-card--preview .wrapper {
  width: calc(100% - 60px);

  a {
    color: $greyish-brown;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: calc(100% - 410px);
    }

    .avatar {
      width: 53px;
    }

    .description {
      width: calc(100% - 80px);
    }
  }

  .counts {
    margin-left: 68px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin-left: 0;
    }

    .members-count  i {
      color: #{$tomato-red};
    }

    .skills-count  i {
      color: #{$pending};
    }

    .languages-count  i {
      color: #{$medium-green};
    }
  }
}

.user-group-card--preview, .manage-user-groups-modal-item, .user-group-card {
  .avatar {
    border-radius: 4px;
  }
}

.manage-user-groups-modal-list .manage-user-groups-modal-item {
  border: 1px solid $graphite-light;
}

.manage-user-groups-modal-item, .user-group-card {
  .avatar {
    width: 32px;
  }
}

.user-group-card .edit-icon {
  cursor: pointer;
}

.opportunity-invite-groups-modal .group-option a {
  color: $black;

  .avatar {
    width: 24px;
  }
}

.opportunity-invite-groups-modal .selected-group .avatar {
  width: 36px;
}

.talent-portfolio__container {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}

.user-profile__tabs {
  .card {
    background-color: transparent;
  }

  .card--pending .card-body {
    background-color: rgba(221,163,84,0.1);
  }

  .custom-field-template-name {
    font-size: 16px;
  }

  .answer-value {
    font-size: 12px;

    .answer-label {
      font-size: 14px;
    }
  }
}

.profile-onboarding-collapsible {
  .area-collapsible__header .people-onboarding-collapsible-header-content {
    max-width: 100%;
    width: calc(100% - 3rem);

    .btn {
      display: none;
    }
  }

  &.expanded {
    .area-collapsible__header .btn {
      display: inline-block;
    }
  }
}

.profile-onboarding-submission {
  background-color: $pale-gray-two;
  border-radius: $borderRadius;

  .header-title {
    cursor: pointer;

    .toggle {
      transition: transform 0.5s;
    }
  }

  &.open .header-title .toggle {
    transform: rotate(180deg);
  }
}

.profile-onboarding_us-tax-permission-warning {
  color: $pending;
}
